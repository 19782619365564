// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Model
import { ApiResponse } from '@core/models/api-response.model';
// Service
import { environment } from '@env/environment';
// Third Party
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

@Injectable({
  providedIn: 'root'
})
export class FingerprintHttpService {

  fingerprintId: string;

  constructor(private http: HttpClient) {
    // Get value and remove from local storage
    if (localStorage.getItem('fid2') != null) {
      if (localStorage.getItem('fid2') != 'undefined' && typeof localStorage.getItem('fid2') != 'undefined') {
        this.fingerprintId = localStorage.getItem('fid2');
      }
      localStorage.removeItem('fid2');
    } else {
    }

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        setTimeout(() => localStorage.removeItem('fid2'), 500);
      }
      else {
        localStorage.setItem('fid2', this.fingerprintId);
      }
    });

    // Listen to window event, when closing window execute this
    window.onbeforeunload = () => {
      this.setToLocalStorage();
    }
  }

  // Call our API to do verification
  async verify(data?: any) {
    if (window.location.pathname != '/launch') {
      if (!this.fingerprintId && environment.production) {
        await this.getFingerprint(data);
      }
      this.http.post<ApiResponse>(`/verify`, data).subscribe();
    }
  }

  // When user leaving the site, call this.
  private setToLocalStorage() {
    if (this.fingerprintId) {
      localStorage.setItem('fid2', this.fingerprintId);
    }
  }

  // Call fingerprintjs to get fingerprint
  private getFingerprint(data?: any) {
    return new Promise<void>((resolve, reject) => {
      const fp2 = FingerprintJS.load({ apiKey: 'fvqH8JWD0rLvqy77k0eA', region: 'ap', endpoint: 'https://fp.608939.com' });
      fp2.then(fp => fp.get()).then(result => {
        this.fingerprintId = result.visitorId;
        this.http.post<ApiResponse>(`/fingerprintlog`, { ...data, fingerprintId: this.fingerprintId }).subscribe();
        resolve();
      });
    });
  }

}
