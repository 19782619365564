<!-- Modal -->
<div class="block mx-auto modal-box">
   <div class="modal1" id="modal-login">
      <button title="Close (Esc)" type="button" class="mfp-close p-t-10" (click)="onCloseDialog()">×</button>
      <div class="row">
         <div class="col-lg-12">
            <div class="p-t-20 p-r-20 p-l-20">
               <div class="d-flex">
                  <div class="text-left mb-0 d-flex align-items-center">
                     <span *ngIf="this.data.reupload === true" [innerHTML]="svg.receiptIcon | safeHtml" class="bill mr-2" style="float: left;"></span>
                     <h3 class="m-0" style="position: relative;">{{ this.data.reupload === true ? ('Reupload Document' | translate | uppercase) : ('E-Wallet Verification' | translate | uppercase) }}</h3>
                  </div>
               </div>
               <hr>
               <div class="col-12 p-l-0 p-r-0">
                  <ng-container *ngIf="this.data.reupload === true else Other">
                     <div class="ewallet-detail mb-4">
                        <p class="text-center m-b-0">
                           {{'Reason' | translate}}: {{ this.data.reason }}
                        </p>
                     </div>
                  </ng-container>
                  <ng-template #Other>
                     <p class="text-justify">{{ 'We only accept E-wallet account of Premium / Premier / Full Service level. Please upload screenshot of your E-wallet profile to proceed.' | translate }}</p>
                  </ng-template>
               </div>
               <form class="row" [formGroup]="form" id="ewallet-verification-modal-form" name="ewallet-verification-modal-form">
                  <div class="col-12">
                     <label *ngIf="this.data.reupload === false">{{ 'Upload Document' | translate }}</label>
                     <div class="row">
                        <div class="col d-flex flex-row flex-wrap">
                           <div class="receipt-box d-flex justify-content-center align-items-center" *ngFor="let image of uploaded_images; let i = index">
                              <img *ngIf="!image.isPdf" [src]="image.path" alt="{{ 'Image Preview' | translate }}" />
                              <iframe *ngIf="image.isPdf" width="100%" height="100%" [src]="image.path" frameborder="0" style="border:0" allowfullscreen></iframe>
                              <div class="trash-img-div d-flex justify-content-end align-items-start">
                                 <span [innerHTML]="svg.trashIcon | safeHtml" (click)="onRemoveFileMulti(i)"></span>
                              </div>
                           </div>
                           <div *ngIf="uploaded_images.length < max_receipts" class="receipt-box d-flex justify-content-center align-items-center">
                              <input type="file" accept="image/*, application/pdf" (change)="onUploadFileMulti($event)" #myFileInput hidden />
                              <span *ngIf="!uploadInProgress" class="upload-action-btn" [innerHTML]="svg.uploadIcon | safeHtml" (click)="myFileInput.click()"></span>
                              <span *ngIf="uploadInProgress" class="spinner-border"></span>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 p-0">
                        <p>{{ 'Max file size is 10MB'| translate}}</p>
                     </div>

                     <div class="col-12 p-r-0 p-l-0 p-t-20 text-center">
                        <button type="submit" class="button-blue w-100" [ngClass]="{'disabled': disableButton}" [disabled]="disableButton" (click)="onSave()">{{ 'Submit' | translate }}</button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
<!--End modal-->
<app-swal-alert [message]="messages$ | async" [success]="isSuccess" [ewalletVerification]="true"></app-swal-alert>