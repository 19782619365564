import { AppState } from '@store/reducers';
import { select, Store } from '@ngrx/store';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { Observable } from 'rxjs';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { MatDialog } from '@angular/material/dialog';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-game-menu',
  templateUrl: './game-menu.component.html',
  styleUrls: ['./game-menu.component.scss']
})
export class GameMenuComponent implements OnInit {
  svg: any = svgIconList;

  @Input()
  fixed: string;

  @Input()
  providers: any;

  @Input()
  gameCategoryCode: string;

  @Input()
  gameCategoryName: string;

  @Output()
  demoEmitter = new EventEmitter<boolean>();

  navPath = '/assets/images/navigation-tab/';

  activeProviderTab: string;

  isSlotsPage$ = this.gameProviderHttpService.isSlotsPage$;
  isDemo$ = this.gameProviderHttpService.isDemo$;

  countryCode = localStorage.getItem('country_code');
  availableProvider = JSON.parse(localStorage.getItem('available_providers'));
  isLoggedIn$: Observable<boolean>;

  tabs = [
    {code: 'LC', link: 'casino', name: 'Casino', svg: this.svg.casinoIcon},
    {code: 'SL', link: 'slots', name: 'Slots', svg: this.svg.slotsIcon},
    {code: 'FS', link: 'fishing', name: 'Fishing', svg: this.svg.fishingIcon},
    {code: 'ES', link: 'esports', name: 'Esports', svg: this.svg.esportsIcon},
    {code: 'SP', link: 'sports', name: 'Sports', svg: this.svg.sportsIcon},
    {code: 'CG', link: 'crash-game', name: 'Crash Game', svg: this.svg.crashIcon},
    {code: 'TB', link: 'table', name: 'Table', svg: this.svg.tableIcon},
  ];

  minTabWidth = 0;
  activeTabIndex = 0;

  constructor(
    private store: Store<AppState>,
    private eventEmitterService: EventEmitterService,
    private gameProviderHttpService: GameProviderHttpService,
    public dialog: MatDialog,
    private el: ElementRef
  ) { }

  ngOnInit() {
    if (this.availableProvider['LT'] !== undefined && this.availableProvider['LT'].length > 0) {
      this.tabs.push({code: 'LT', link: 'lottery', name: 'Lottery', svg: this.svg.lotteryIcon});
    }

    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.activeProviderTab = this.gameCategoryCode ? this.gameCategoryName : null;
    this.eventEmitterService.topBarTabVar = this.eventEmitterService.topBarTabEmitter.subscribe(() => {
      this.providers = JSON.parse(localStorage.getItem('available_providers'));
      this.countryCode = localStorage.getItem('country_code');
      if (this.providers !== undefined) {
        Object.keys(this.providers).forEach((key) => {
          if (this.providers[key].length < 1) {
            // Filter out empty categories
            this.tabs = this.tabs.filter(tab => tab.code !== key);
          }
        });
        this.setTab();
      }
    });
    if (this.providers !== undefined) {
      Object.keys(this.providers).forEach((key) => {
        if (this.providers[key].length < 1) {
          // Filter out empty categories
          this.tabs = this.tabs.filter(tab => tab.code !== key);
        }
      });
      this.setTab();
    }
  }

  onSelectCategoryTab(gameCategoryCode: string, gameCategoryName: string, index: number) {
    this.activeTabIndex = index;
    this.activeProviderTab = gameCategoryName;

    const gameCategory = {
      code: gameCategoryCode,
      name: gameCategoryName
    };
    this.eventEmitterService.onSelectProviderTabEmitter(gameCategory);

    if (gameCategoryCode === 'SL') {
      this.gameProviderHttpService.isSlotsPage$.next(true);
    } else {
      this.gameProviderHttpService.isSlotsPage$.next(false);
    }
    this.gameProviderHttpService.toggleSlotsDemoStorage();
  }

  // onDemoMode(e: Event) {
  //   if (+localStorage.getItem('isDemoSelected') === 0) {
  //     localStorage.setItem('isDemoSelected', '1');
  //   } else {
  //     localStorage.setItem('isDemoSelected', '0');
  //   }

  //   this.gameProviderHttpService.toggleSlotsDemoStorage();
  //   this.demoEmitter.emit(+localStorage.getItem('isDemoSelected') === 1)

  //   const isShowDemoNotice = localStorage.getItem('isShowDemoNotice');
  //   if ((isShowDemoNotice === null || +isShowDemoNotice === 1) && +localStorage.getItem('isDemoSelected') === 1) {
  //     this.openDialogBy(DemoNoticeComponent, true);
  //   }

  // }

  // onShowDemoNotice() {
  //   this.openDialogBy(DemoNoticeComponent, false);
  // }

  isBelongFromRoute(slug: string) {
    if ((window.location.pathname).startsWith(`/${slug}`) || this.activeProviderTab === slug) {
      return true;
    }

    return false;
  }

  private setTab(){
    this.minTabWidth = 100/(this.tabs.length);
  }

  private openDialogBy(componentRef: any, onSwitch: boolean) {
    this.dialog.open(componentRef, {
      width: 'auto',
      data: {
        onSwitch: onSwitch
      }
    });
  }

}
