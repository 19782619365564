import { DOCUMENT } from '@angular/common';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, OnInit, DoCheck, AfterViewInit, HostListener, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { SignupModalComponent } from '@shared/signup-modal/signup-modal.component';
import { Store, select } from '@ngrx/store';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { AuthActions } from '@core/store/auth/auth-action.types';
import { Observable, Subscription, timer, of } from 'rxjs';
import { AppState } from 'app/store/reducers';
import { WalletModalComponent } from '@shared/wallet-modal/wallet-modal.component';
import { AuthHttpService } from '@core/services/auth-http.service';
import { LanguageModalComponent } from '@shared/language-modal/language-modal.component';
import { PortalMessageHttpService } from '@core/services/portal-message-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationEnd,
  NavigationCancel,
  NavigationStart
} from '@angular/router';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { tap, switchMap, delay, filter } from 'rxjs/operators';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { PortalMemberProfile } from '@core/models/portal-member-profile.model';
import { logout } from '@core/store/auth/auth.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as fromAuthActions from '@core/store/auth/auth.actions';
import { ForgotPasswordModalComponent } from '@shared/forgot-password-modal/forgot-password-modal.component';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import * as moment from 'moment';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { environment } from '@env/environment';
import { WebsocketService } from '@core/services/websocket.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { PredictionHttpService } from '@views/modules/game/services/prediction-http.service';
import { MenuBarHttpService } from '@core/services/menu-bar-http.service';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { BankAccountHttpService } from '@core/services/bank-account-http.service';
import { RequestReceipt } from '@core/models/request-receipt.model';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, AfterViewInit, DoCheck, AfterViewInit {
  svg: any = svgIconList;

  mode: string = window.mode;
  isLoggedIn$: Observable<boolean>;
  loggedUser$: Observable<PortalMemberProfile>;
  memberUsername$ = this.dashboardHttpService.memberUsername$;
  userFlag: string;
  currentCountryCode = localStorage.getItem('country_code');
  currentLangCode = localStorage.getItem('language_code') == 'ZH' ? '中文' : localStorage.getItem('language_code');

  playtechPage = localStorage.getItem('playtechCategory') !== null ? true : false;
  availableProvider = JSON.parse(localStorage.getItem('available_providers'));
  popup_ids = localStorage.getItem('popup_ids') ?? [];

  // dateTime: string;
  unreadMessages$ = this.portalMessageHttpService.messageCount$;
  messages$ = this.portalMessageHttpService.messages$;
  newMessage = false;

  mobileDomain: string;
  desktopDomain: string;
  counter: number;
  timer: any;

  showMiniLogin = false;
  formMiniLogin: FormGroup;
  disableButton = false;
  checkValidity = false;
  availableCountryAndLanguages = this.dropdownHttpService.availableCountryAndLanguages;
  currentDomain = JSON.parse(sessionStorage.getItem('mainDomainBlocked')) ? this.getAltDomain() : environment.domainName;
  showKYCGateKeeper: boolean;
  memberKYC: string;
  memberGroup: string;
  isPredictionTabClicked = true;
  verificationRejected: boolean;

  private unsubscribe: Subscription[] = [];
  homeIcon = `assets/images/topnav/home.png`;
  mobileIcon = `assets/images/topnav/mobile.png`;
  fixed = false;

  currentDateUTC = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  showTheme = false;
  isLaunchGamePage = false;

  themes = {
    "Christmas": {
      logo: 'assets/integrations/theme/logo-xmas-2023.gif',
      // model: 'assets/integrations/theme/model-christmas.png',
      startDate: '2023-12-20',
      endDate: '2024-01-03',
    },
    "CNY": {
      logo: 'assets/integrations/theme/logo-cny-2024.gif',
      // model: 'assets/integrations/theme/model-cny.png',
      startDate: '2024-02-06',
      endDate: '2024-03-01',
    },
    "Mid Autumn": {
      logo: 'assets/integrations/theme/logo-mid-autumn-2023.gif',
      // model: 'assets/integrations/theme/model-cny.png',
      startDate: '2023-09-18',
      endDate: '2023-10-06',
    },
    "Songkran Festival": {
      logo: 'assets/integrations/theme/logo-songkran-2024.gif',
      startDate: '2024-04-04',
      endDate: '2024-04-18',
    },
    "Dragon Boat Festival": {
      logo: 'assets/integrations/theme/dragonboat-festival-2024.gif',
      startDate: '2024-06-03',
      endDate: '2024-06-17',
    },
    "Mid Autumn 2024": {
      logo: 'assets/integrations/theme/logo-mid-autumn-2024.gif',
      startDate: '2024-09-10',
      endDate: '2024-09-24',
    }
  }

  menuLists;
  menuMaxItems = 9;

  altName = environment.sitePrefix.toUpperCase();
  hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');

  affiliateDomain = this.dropdownHttpService.affiliateDomain;
  affiliateUrl = '';

  onMaintenancePage = false;

  sitePrefix = environment.sitePrefix;

  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  depositData: RequestReceipt = null;

  predictionMenuHidden: boolean = false;
  newMenuHidden: boolean = false;

  @HostListener('window:scroll')
  pageScroll(e: Event) {
    this.stickyGameMenu();
  }

  private websocketService: WebsocketService | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private authService: AuthHttpService,
    private store: Store<AppState>,
    private portalMessageHttpService: PortalMessageHttpService,
    private portalMemberProfile: PortalMemberProfileHttpService,
    private dashboardHttpService: DashboardHttpService,
    private router: Router,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private fb: FormBuilder,
    private dropdownHttpService: DropdownHttpService,
    private walletHttpService: WalletHttpService,
    private snackBar: MatSnackBar,
    private predictionHttpService: PredictionHttpService,
    private menuBarHttpService: MenuBarHttpService,
    private requestReceiptHttpService: RequestReceiptHttpService,
    private bankAccountHttpService: BankAccountHttpService,
  ) {
    // No need initialize websocket connection when in '/launch' page
    if (window.location.pathname !== '/launch') {
      this.websocketService = new WebsocketService(snackBar);
    }
    else {
      this.isLaunchGamePage = true;
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.onMaintenancePage = (window.location.pathname) == '/maintenance' ? true : false;
    }, 500)
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.getUserKYC();
    });

    setTimeout(() => { // delayed to get the country on domain
      if (!Object.keys(this.availableCountryAndLanguages[this.currentDomain]).includes(this.currentCountryCode)) {
        this.currentCountryCode = 'MY';
        localStorage.setItem('country_code', this.currentCountryCode);
        this.getFlag();
      }
      this.getMobileDomain();
      this.setAltName();
      this.getAffiliateUrl();
    }, 2000)

    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));

    if (isLoggedIn) {
      this.getFlag();
    }

    if (!this.authService.isTokenExpired()) {
      this.loggedUser$ = this.portalMemberProfile.getProfile().pipe(
        tap(user => this.dashboardHttpService.refreshMemberName(user.username))
      );
    }

    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.reloadMessageEmitter.subscribe((name: any) => { });
    }

    this.eventEmitterService.topBarTabVar = this.eventEmitterService.topBarTabEmitter.subscribe(() => {
      this.availableProvider = JSON.parse(localStorage.getItem('available_providers'));
    });

    this.eventEmitterService.flagEmitter.subscribe((name: any) => {
      this.currentCountryCode = localStorage.getItem('country_code');
      this.getFlag();
    });

    this.eventEmitterService.verificationTimerEmitter.subscribe((name: string) => {
      if (sessionStorage.getItem('OTP_timer') !== null) {
        this.onTimer(Number(sessionStorage.getItem('OTP_timer')) - 1);
      } else {
        this.onTimer(60 - 1);
      }
    });

    this.eventEmitterService.buttonEmitter.subscribe(() => {
      this.disableButton = false;
    });

    this.eventEmitterService.topbarEventClicked.subscribe(res => this.isPredictionTabClicked = res);

    this.portalMessageHttpService.messageSubject.subscribe(res => {
      this.newMessage = res.event_type === 'new' ? true : false;
    });

    this.initializeMiniLoginForm();
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        this.newRequestIncoming.subscribe(() => {
          this.depositData = RequestReceiptHttpService.requestReceipt;
        })
        setTimeout(() => {
          var userdata = JSON.parse(localStorage.getItem('user_data'));
          const memberAccountId = userdata.id;

          this.getUserKYC();
          this.getMenuBar();

          if (userdata.country_code == 'ID') {
            this.getRejectedVerification(false);
            this.eventEmitterService.updateverificationEmitter.subscribe(() => {
              this.getRejectedVerification(true);
            });
          }

          // check whether to show noti for "Prediction" tab
          this.predictionHttpService.checkMemberPredictionTab(memberAccountId).subscribe(res => {
            this.isPredictionTabClicked = res.clicked;
          });

          // No need to call websocket when in /launch page
          if (window.location.pathname !== '/launch') {
            this.portalMessageHttpService.getUnread().subscribe(() => {
              this.portalMessageHttpService.messageWebsocket(memberAccountId);
            });

            this.memberWebsocket(memberAccountId);
            this.vipEligibilityWebsocket(memberAccountId);
            this.updateMemberInfoWebsocket(memberAccountId);
            this.updateMemberEventClickWebsocket(memberAccountId);
            this.createEventSendNotificationWebsocket();
          }
        }, 500);
        if (this.router.url === '/member/message') {
          this.newMessage = false;
        }
      } else {
        this.getMenuBar();
      }
    });

    Object.keys(this.themes).forEach(key => {
      this.themes[key].started = this.festiveStarted(this.themes[key].startDate);
      this.themes[key].end = this.festiveEnd(this.themes[key].endDate);
      this.themes[key].data = [];
      if (this.themes[key].started && !this.themes[key].end) {
        this.showTheme = true;
      }
    });

    // Resize topbar as user adjusts
    window.addEventListener('resize', function (event) {
      this.setMenuMaxItems();
    }.bind(this), true);
  }

  ngAfterViewInit() { }

  private setAltName() {
    let countryCode = null;
    Object.keys(this.availableCountryAndLanguages[this.currentDomain]).forEach(key => {
      if (this.availableCountryAndLanguages[this.currentDomain][key][environment.domainEnv].includes(this.hostname)) {
        countryCode = key;
      }
    });

    if (countryCode === 'SG' && environment.sitePrefix == 'bp77') {
      this.altName = 'Best 🕹️Online Casino🎰 in Singapore | BP77';
    }
  }

  stickyGameMenu() {
    this.fixed = false;
    if (this.document.documentElement.scrollTop > 91) {
      this.fixed = true;
    }
  }

  onTimer(startTime: number) {
    this.counter = startTime;
    window.clearInterval(this.timer);
    this.timer = setInterval(() => {
      sessionStorage.setItem('OTP_timer', (this.counter--).toString());
      if (this.counter === 0) {
        window.clearInterval(this.timer);
        sessionStorage.removeItem('OTP_timer');
      }
    }, 1000);
  }

  ngDoCheck() {
    window.onscroll = () => { scrollFunction(); };
    function scrollFunction() {
      if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
        if (document.getElementById('header-wrap') !== null) {
          document.getElementById('header-wrap').style.background = '#0E131C';
        }
      }
    }
  }

  submitMiniLogin() {
    //this.disableButton = true;
    this.checkValidity = true;
    if (this.formMiniLogin.valid) {
      this.loadingBar.start();
      const { username, password } = this.formMiniLogin.value;

      this.authService.login(username, password).pipe(
        tap((res) => {
          this.eventEmitterService.onUpdateUsername(username);
          if (res.data.user.country_code !== this.currentCountryCode) {
            this.eventEmitterService.onResetAnnouncementEmitter();
          }
          this.store.dispatch(fromAuthActions.login({
            user: res.data.user,
            token: res.data.token,
          }));

          this.getMobileDomain();
          localStorage.setItem('verified_number', res.data.user.phone_validated);
          if (res.data.user.name !== '' && res.data.user.email !== '') {
            this.walletHttpService.getMemberBalance().subscribe(wallet => {
              if (wallet.balance <= 0) {
                this.eventEmitterService.onAvailableProviderEmitter();
                if (res.data.user.country_code === this.currentCountryCode) {
                  this.router.navigate(['/member']);
                } else {
                  window.location.href = '/member';
                }
              }
              of(null).pipe(
                delay(1000), tap(() => this.eventEmitterService.onTopBarTabEmitter()
                )).subscribe();
              localStorage.setItem('profile', res.data.user.name);
            });
          }
          this.loadingBar.complete();
          //this.disableButton = false;
          this.checkValidity = false;
        })
      ).subscribe();
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authService.forceLowerCaseInputControl(this.formMiniLogin, controlName, event);
  }

  onOpenDialog(type: string) {
    switch (type) {
      case 'login':
        this.openDialogBy(LoginModalComponent, 'login');
        break;

      case 'signup':
        this.openDialogBy(SignupModalComponent, 'signup');
        break;

      case 'wallet':
        this.openDialogBy(WalletModalComponent, 'wallet');
        break;

      case 'language':
        this.openDialogBy(LanguageModalComponent, 'language');
        break;

      case 'forgotpassword':
        this.openDialogBy(ForgotPasswordModalComponent, 'forgotpassword');
        break;
    }

  }

  onLogout() {
    this.loadingBar.start();
    this.authService.logout().subscribe(() => {
      window.location.href = this.mode == 'Members Only' ? '/login' : '/';
      this.store.dispatch(AuthActions.logout());
    });
  }

  // onHover(icon: any, mouseIn?: boolean) {
  //   switch (icon) {
  //     case 'wallet':
  //       this.walletIcon = mouseIn ? 'assets/images/member/wallet.png' : 'assets/images/topnav/wallet.png';
  //       break;
  //     case 'portal':
  //       this.portalIcon = mouseIn ? 'assets/images/topnav/account-active.png' : 'assets/images/topnav/account.png';
  //       break;
  //     case 'message':
  //       this.messageIcon = mouseIn ? 'assets/images/topnav/message-active.png' : 'assets/images/topnav/message.png';
  //       break;
  //     case 'home':
  //       this.homeIcon = mouseIn ? 'assets/images/topnav/home-active.png' : 'assets/images/topnav/home.png';
  //       break;
  //     case 'mobile':
  //       this.mobileIcon = mouseIn ? 'assets/images/topnav/mobile-active.png' : 'assets/images/topnav/mobile.png';
  //       break;
  //   }
  // }

  returnZero() {
    return 0;
  }

  navToAff() {
    window.location.href = this.affiliateUrl;
  }

  isUserLoggedIn() {
    return this.authService.isUserLoggedIn;
  };

  get isTokenExpired() {
    if (!this.authService.isTokenExpired()) {
      return false;
    }
    this.store.dispatch(logout());
    localStorage.setItem('redirectToLogin', '1');
    return true;
  }

  private getUserKYC() {
    const userData = localStorage.getItem('user_data');
    this.showKYCGateKeeper = window.location.pathname === '/member/referrals' || window.location.pathname === '/vip' || window.location.pathname === '/referral' ? true : false;
    if (this.showKYCGateKeeper && userData !== null) {
      this.memberKYC = JSON.parse(userData).KYC;
      this.memberGroup = JSON.parse(userData).member_group.name;
    }
  }

  private openDialogBy(componentRef: any, type: string) {
    this.dialog.open(componentRef, {
      width: 'auto',
    });
  }

  private initializeMiniLoginForm() {
    this.formMiniLogin = this.fb.group({
      username: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(320)
        ])
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100)
        ])
      ]
    });
  }

  getMenuBar() {
    this.menuBarHttpService.getMenuBar().subscribe((res) => {
      this.menuLists = res;

      setTimeout(() => {
        this.setMenuMaxItems();
      }, 100);
    });
  }

  private getFlag() {
    var code = localStorage.getItem('country_code') ? localStorage.getItem('country_code').toUpperCase() : "MY";
    this.userFlag = `/assets/images/language/${code}.png`;
    this.currentLangCode = localStorage.getItem('language_code') == 'ZH' ? '中文' : localStorage.getItem('language_code');
  }

  getCurrentUrl() {
    return this.router.url;
  }

  private getMobileDomain() {
    if (this.hostname.split('.').length > 2) {
      this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
    }
    const userData = localStorage.getItem('user_data');
    const userToken = localStorage.getItem('user_token');

    if (this.hostname.split('.').length > 2) {
      this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
    }

    let countryCode = localStorage.getItem('country_code') === null ? 'MY' : localStorage.getItem('country_code');
    // Full domain
    let fullDomain = (environment.https ? 'https://' : 'http://') + this.availableCountryAndLanguages[this.currentDomain][countryCode]['mobilePreExt'][environment.domainEnv] + this.hostname;

    let crossLoginParam = `?userToken=${userToken}&userData=${userData}`;

    if (localStorage.getItem('user_data') === null) {
      this.mobileDomain = fullDomain;
    } else {
      this.mobileDomain = fullDomain + crossLoginParam;
    }
  }

  getCurrentDomain() {
    let hostname = window.location.hostname;
    let domain = hostname.replace('www.', '');
    return domain;
  }

  private getAffiliateUrl() {
    let affCountryObj = this.affiliateDomain[this.currentCountryCode];
    let targetDomain = this.getCurrentDomain();
    this.affiliateUrl = (environment.https ? 'https://' : 'http://') + affCountryObj.desktopPreExt[environment.domainEnv] + (affCountryObj[environment.domainEnv].find(domain => domain == targetDomain) ? targetDomain : affCountryObj[environment.domainEnv][0]) + `?lang=${this.currentCountryCode}_${localStorage.getItem('language_code')}`;
  }

  private festiveStarted(startDate: string) {
    return moment(this.currentDateUTC).isSameOrAfter(startDate + ' 00:00:00');
  }

  private festiveEnd(endDate: string) {
    return moment(this.currentDateUTC).isAfter(endDate + ' 23:59:59');
  }

  private memberWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("member-channel." + memberAccountId)
      .listen(`.MemberEvent`, (data) => {
        const cta = data.CTA;

        if (cta) {
          // do not show deposit dialog if pop up dialog available
          if (cta == 'deposit' && this.popup_ids.length > 0) {
            return;
          }

          this.websocketService.ctaNotification({
            ...data,
            buttonConfirm: cta,
            buttonCancel: "No, Thanks",
          });
        } else {
          this.messages$.next([data.title]);
        }
      });
  }

  private vipEligibilityWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("vip-eligibility-channel." + memberAccountId)
      .listen(`.VIPEligibilityEvent`, (data) => {
        const cta = data.CTA;

        if (data.CTA) {
          this.websocketService.ctaNotification({
            ...data,
            buttonConfirm: cta,
            buttonCancel: "No, Thanks",
          });
        } else {
          this.messages$.next([data.title]);
        }
      });
  }

  private updateMemberInfoWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("update-member-info-channel." + memberAccountId)
      .listen(`.UpdateMemberInfoEvent`, (data) => {
        const member = data.member;
        this.portalMemberProfile.updateLocalUserData(member);
        localStorage.setItem('verified_number', member.phone_validated);
      });
  }

  private updateMemberEventClickWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("update-member-event-click-channel." + memberAccountId)
      .listen(`.UpdateMemberEventClickEvent`, (data) => {
        this.isPredictionTabClicked = data.clicked;
        if (data.clicked == 0) {
          data.events.forEach(id => this.eventEmitterService.eventListStatusUpdate.emit(id));
          this.eventEmitterService.eventListStatusLabelUpdate.emit(data.statuses);
        }
      });
  }

  private createEventSendNotificationWebsocket() {
    this.websocketService
      .getChannel('create-event-send-notification-channel')
      .listen(`.CreateEventSendNotificationEvent`, (data) => {
        this.isPredictionTabClicked = data.clicked;
      });
  }

  private getAltDomain() {
    let hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
    // E.g: localhost.com
    // Get localhost.com
    if (hostname.split('.').length > 2) {
      hostname = hostname.substr(hostname.indexOf('.') + 1);
    }

    // First Alternative
    if (
      JSON.stringify(this.availableCountryAndLanguages[environment.altDomainName]).includes('"' + hostname + '"') ||
      environment.altDomainName.split('|').includes(hostname)
    ) {
      return environment.altDomainName;
    }
    // Second Alternative
    else if (
      JSON.stringify(this.availableCountryAndLanguages[environment.altDomainNameTwo]).includes('"' + hostname + '"') ||
      environment.altDomainNameTwo.split('|').includes(hostname)
    ) {
      return environment.altDomainNameTwo;
    }
  }

  getRouterLink(link: string): any[] {

    if (!link) return;

    // There might will be possible 3 type of link will store. (Internal URL, Internal URL with domain full path or External site URL)
    // /casino, http://localhost:4200/casino, https://www.mcbaffiliate.com/
    // So this function is used to handle these URL see which menu bar should set to Active
    if (link.startsWith('http') || link.startsWith('//')) {
      const url = new URL(link);
      const targetHostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, ''); // Change this to your own hostname
      const isSameHostname = url.hostname === targetHostname;

      if (isSameHostname) {
        return [url.pathname];
      }
    }

    return [link];
  }

  navigateTo(link: string): void {
    if (!link) return;
    if (link == '/affiliate') {
      this.navToAff();
    } else if (link.startsWith('/external?link=')) {
      window.location.href = link.replace('/external?link=', '');
    } else {
      this.router.navigateByUrl(link);
    }
  }

  toggleMoreDropdown(clickOutside = false) {
    const dropdown = this.document.getElementById('more-dropdown');
    const arrow = this.document.getElementById('more-down-arrow');
    const predictionNotification = this.document.getElementById('prediction-notification-more');

    if (clickOutside) {
      dropdown.classList.add('d-none');
      arrow.classList.remove('rotate-180');
      predictionNotification?.classList.remove('d-none');
    } else {
      if (dropdown.classList.contains('d-none')) {
        dropdown.classList.remove('d-none');
        arrow.classList.add('rotate-180');
        predictionNotification.classList.add('d-none');
      } else {
        dropdown.classList.add('d-none');
        arrow.classList.remove('rotate-180');
        predictionNotification.classList.remove('d-none');
      }
    }
  }

  setMenuMaxItems() {
    const width = this.document.getElementById('navigation-menu').offsetWidth;

    if (width >= 1300) {
      this.menuMaxItems = 9;
    } else if (width >= 1000) {
      this.menuMaxItems = 7;
    } else if (width >= 700) {
      this.menuMaxItems = 5;
    } else {
      this.menuMaxItems = 3;
    }
    this.predictionMenuIsHide();
  }

  predictionMenuIsHide() {
    var hiddenMenu = [];
    for (let i = this.menuMaxItems; i < this.menuLists.length; i++) {
      hiddenMenu.push(this.menuLists[i]);
    }
    this.predictionMenuHidden = (hiddenMenu.find(x => x.link == "/prediction") ? true : false);

    var newMenu  = [];
    for (let i = this.menuMaxItems; i < this.menuLists.length; i++) {
      newMenu.push(this.menuLists[i]);
    }
    this.newMenuHidden = (newMenu.find(x => x.new == 1) ? true : false);
  }

  getRejectedVerification(emitter: boolean) {
    this.bankAccountHttpService.getverificationDetails().subscribe(res => {
      if (res.length > 0) {
        localStorage.setItem('rejected_verification', 'true');
        this.verificationRejected = true;
      } else {
        localStorage.setItem('rejected_verification', 'false');
        this.verificationRejected = false;
      } 

      if (emitter == true) {
        this.eventEmitterService.onVerificationStorageEmitter();
      }
    });
  }
}
