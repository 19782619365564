// Angular
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BottomBarService {

    // TODO: Update brand name 
    contentData = {
        SG: {
            main: {
                main: {
                    headingOne: null,
                    title: 'Online casino Singapore',
                    content: 'Online casino Singapore is a great place to play your favorite online casino games. There are many different games available, and you can find the perfect one for you. Whether you\'re a fan of slots, table games, or poker, there\'s something for everyone at online casino Singapore.',
                }
            },
            sports: {
                wbet: {
                    headingOne: null,
                    title: 'Wbet',
                    content: 'One of Southeast Asia\'s most popular sports betting products has been redesigned from the ground up to take on today\'s online sports betting sector. Online live sports betting provider WBet prides itself on providing the finest possible experience for its clients in running an online bookmaking business with decades of combined industry knowledge from key Asian sports betting market participants.'
                },
                cmd: {
                    headingOne: null,
                    title: 'Cmd368',
                    content: 'CMD368, an online betting and sports betting platform that was established in Cambodia back in 2016.Since then, CMD368 has gained popularity among Asian players and bettors. CMD368 is especially known in countries such as Malaysia, Vietnam, Indonesia, and Thailand. It became the go-to place for avid sports and e-sports fans.'
                },
            },
            casino: {
                dg: {
                    headingOne: null,
                    title: 'Dream gaming',
                    content: 'Dream Gaming stands out among online casinos in Malaysia and around Asia. The platform is well-known for using a team of skilled dealers in a real casino environment. Dream Gaming also has high-end equipment and quick technical support, ensuring that players have pleasant high-quality games. The brand lets people participate in games across a variety of platforms. Therefore, players can access the game from anywhere!'
                },
                bg: {
                    headingOne: null,
                    title: 'Big gaming casino',
                    content: 'Big Gaming slots were designed specifically to suit Asians by providing quick connections to the gambling industry. Choose the most popular Big Gaming Casino for a diverse selection of games and the best bonuses for Singaporean gamers and earn real money prizes!'
                },
                sexy: {
                    headingOne: null,
                    title: 'Sexybaccarat',
                    content: 'This is a call to all Baccarat fans all across the world. If that describes you, keep reading! Despite the fact that there are various online casinos that provide live dealer games, the majority of them are designed to replicate the traditional land-based casino experience. Basically, they\'re attempting to replicate the appearance and feel of a traditional land-based casino.'
                },
                evo: {
                    headingOne: null,
                    title: 'Evolution gaming casino',
                    content: 'Is it possible to have a casino gaming company that doesn\'t make slot machines? This may seem like a difficult endeavor, yet such a corporation does exist. Its name is Evolution Gaming, and it solely provides live casino solutions to the industry, including roulette, blackjack, and other table and card games that normally require human dealers to conduct a session with their online casinos.'
                },
                sa: {
                    headingOne: null,
                    title: 'Sa gaming casino',
                    content: 'We\'ll look at the casino game creator SA Gaming and the casinos that use its software. Although the majority of best online casinos are from the United States, Australia, and Europe, Asia has a number of enterprises to be proud of. One of them is SA Gaming Casinos.'
                }
            },
            slots: {
                cq9: {
                    headingOne: null,
                    title: 'Cq9',
                    content: 'CQ9 Gaming began in online gaming industry in Asia, which explains the Asian flavor of many of its games; it is currently becoming more well-known in the United States and Europe. It claims to be Asia\'s most popular gaming brand, having more than 150 HTML5 games in its portfolio.'
                },
                kiss: {
                    headingOne: null,
                    title: '918kiss',
                    content: 'There\'s no doubt that 918kiss is one of Malaysia\'s most popular online slot games. It\'s so popular that it has gained a huge following in Malaysia and worldwide. So if you\'re looking for some excitement and want to try your luck at gambling online, 918kiss is a game you should check out.'
                },
                mega: {
                    headingOne: null,
                    title: 'Mega888',
                    content: 'Online games have become a fixture in the lives of many people who are looking for some form of recreation. However, with the wide variety of current options, one may be left wondering which ones they should get into and which ones would offer the most entertainment. Mega888 online casino is an excellent choice for such individuals since it provides many options for slot machines and card games.'
                },
                pp: {
                    headingOne: null,
                    title: 'Pragmatic Play',
                    content: 'The online gaming industry is constantly growing and evolving, and as it does so, new ways to enjoy live casino emerges. One such trend is pragmatic Play - a term used to describe playing desktop digital casino games that maximizes potential wins and minimizes losses.'
                },
                live: {
                    headingOne: null,
                    title: 'Live22',
                    content: 'There are plenty of online casinos in the market. However, only a few casino platforms are specially made for southeast Asian countries. If you wish to play online casino games wherever you are, you should consider downloading Live22 APK.'
                },
                sg: {
                    headingOne: null,
                    title: 'Spadegaming',
                    content: 'Asia may not be considered a significant player in the online casino game, but Spadegaming is on a mission to put the region on the map. To put things into perspective, the Asian market had always been fond of gambling. However, online casinos are not as popular in Asia as in other parts of the world.'
                },
                next: {
                    headingOne: null,
                    title: 'Nextspin',
                    content: 'Digitalization has taken over so many industries, and casino games are no exception. Today, plenty of players worldwide have embraced the wonders of the online casino platform. Instead of going to physical casinos, people get to play games on their mobile devices or computers. This means there is the freedom to play casino games wherever and whenever.'
                },
                haba: {
                    headingOne: null,
                    title: 'Habanero slot',
                    content: 'There are plenty of popular online casinos in the market. Many companies have cemented their position as the leaders in the world when it comes to online slots and casino games. However, not all the best online casinos are famous names. There are hidden gems like Habanero Systems that have enthralled gaming enthusiasts worldwide.'
                }
            },
            esports: {
                cmd: {
                    headingOne: null,
                    title: 'Esports betting',
                    content: 'In recent years, esports has exploded. While most other sporting events have been put on hold or have undergone dramatic changes, esports has become increasingly popular. This rise in popularity is mainly because players can participate in esports regardless of their location.'
                }
            }
        },
        ID: {
            main: {
                main: {
                    headingOne: 'BP77 Leading Casino Online Indonesia',
                    title: 'Selamat Datang di Situs Resmi BP77 Indonesia',
                    content: 'BP77 adalah situs casino online terbesar yang beroperasi di SEA (south East Asia) di semua negara-negara maju yaitu Singapura, Malaysia, Thailand, Indonesia, Vietnam dan Kamboja. Untuk di Indonesia situs BP77 sudah banyak sekali dikenal oleh warga Indonesia dan tidak asing lagi tentunya. Dengan total mencapai puluhan ribu pemain aktif di situs BP77 Indonesia.'
                }
            },
            casino: {
                pp: {
                    headingOne: 'Live Casino | Pragmatic Play',
                    title: null,
                    content: 'Live Casino dari Pragmatic Play menawarkan pengalaman bermain casino secara langsung melalui streaming video. Dengan kata lain, pemain dapat bertaruh pada permainan kasino sebenarnya yang dipandu oleh dealer manusia nyata, namun semuanya dilakukan secara online. Hal ini memberikan pengalaman yang mendekati bermain di kasino fisik, namun dengan kenyamanan bermain dari rumah atau perangkat mobile.'
                },
                evo2: {
                    headingOne: 'Live Casino | Evolution Gaming',
                    title: null,
                    content: 'Evolution Gaming didirikan pada tahun 2006 dan terkenal dengan layanan live casino-nya. Dengan kata lain, Evolution Gaming memungkinkan pemain untuk bermain permainan kasino seperti blackjack, roulette, baccarat, dan lain-lain dengan dealer nyata secara real-time melalui streaming video.'
                },
                dg: {
                    headingOne: 'Live Casino | Dream Gaming',
                    title: null,
                    content: 'Dream Gaming adalah salah satu penyedia permainan live casino online yang populer di Asia. Live casino adalah bentuk perjudian online di mana pemain dapat berinteraksi dengan dealer manusia yang sebenarnya melalui siaran video langsung. Dream Gaming menawarkan berbagai permainan live casino yang mencakup berbagai jenis permainan tradisional seperti blackjack, roulette, baccarat, dan banyak lagi.'
                },
                bg: {
                    headingOne: 'Live Casino | Big Gaming',
                    title: null,
                    content: 'Big Gaming adalah Sebuah penyedia produk perjudian terkemuka di Asia; sebuah merek dengan sistem yang terkenal dan stabil, berfokus pada menyediakan produk berkualitas tinggi dan aman kepada klien. Kami menawarkan layanan one-stop white-label, termasuk permainan Poker, dan white-labeling Internasional, Composite, dan kami mendukung anti-hijacking aplikasi asli.'
                },
                ag: {
                    headingOne: 'Live Casino | Asia Gaming',
                    title: null,
                    content: 'Asia Gaming adalah salah satu penyedia permainan kasino online yang terkenal di Asia. Mereka menawarkan berbagai jenis permainan kasino secara langsung dengan dealer sungguhan yang mengoperasikan permainan secara real-time.'
                },
                pt: {
                    headingOne: 'Live Casino | Playtech',
                    title: null,
                    content: 'Playtech adalah salah satu penyedia perangkat lunak kasino terkemuka di industri iGaming. Berdiri pada tahun 1999, perusahaan ini telah lama menjadi pemain penting dalam pengembangan perangkat lunak untuk perjudian online. Salah satu produk andalannya adalah platform live casino.'
                },
                wm: {
                    headingOne: 'Live Casino | WM Casino',
                    title: null,
                    content: 'WM Casino adalah salah satu platform online yang menyediakan permainan kasino langsung. Mereka biasanya menargetkan pasar Asia dan dikenal dengan berbagai macam permainan tradisional Asia serta permainan kasino populer lainnya di seluruh dunia.'
                },
                sexy: {
                    headingOne: 'Live Casino | Sexy Baccarat',
                    title: null,
                    content: 'Sexy Baccarat Live Casino adalah salah satu penyedia permainan kasino online yang menawarkan permainan baccarat dengan penekanan pada pengalaman hiburan visual yang lebih menarik dan berorientasi pada estetika.'
                },
                ab: {
                    headingOne: 'Live Casino | All Bet',
                    title: null,
                    content: 'All Bet adalah salah satu penyedia perangkat lunak untuk kasino langsung. Kasino langsung memberikan pengalaman bermain kasino yang realistis kepada pemain dari kenyamanan rumah mereka melalui streaming video langsung dari studio kasino.'
                },
                sa: {
                    headingOne: 'Live Casino | SA Gaming',
                    title: null,
                    content: 'SA Gaming adalah penyedia situs kasino online yang populer terutama di pasar Asia. Mereka dikenal dengan berbagai produk permainan mereka, termasuk permainan live casino. Live casino memungkinkan pemain untuk bermain permainan kasino favorit mereka dalam pengaturan langsung dengan dealer sungguhan melalui streaming video.'
                },
                ez: {
                    headingOne: 'Live Casino | Ezugi',
                    title: null,
                    content: 'Ezugi adalah salah satu provider kasino langsung yang populer di industri perjudian online. Didirikan pada tahun 2013, perusahaan ini mengkhususkan diri dalam mengembangkan dan menyediakan solusi permainan kasino langsung berkualitas tinggi untuk operator kasino online di seluruh dunia.'
                },
                cq9: {
                    headingOne: 'Live Casino | CQ9 Gaming',
                    title: null,
                    content: 'CQ9 adalah salah satu penyedia permainan kasino online yang cukup populer di beberapa negara, terutama di pasar Asia. Mereka terkenal dengan slot game mereka, yang menawarkan grafis yang menarik dan gameplay yang inovatif.'
                },
                mg: {
                    headingOne: 'Live Casino | Microgaming',
                    title: null,
                    content: 'Microgaming adalah salah satu penyedia perangkat lunak kasino online terkemuka di dunia. Perusahaan ini dikenal telah mengembangkan berbagai jenis permainan kasino, termasuk slot, meja permainan, dan lain-lain.'
                },
                ebet: {
                    headingOne: 'Live Casino | World Entertainment ',
                    title: null,
                    content: null
                }
            },
            slots: {
                pp: {
                    headingOne: 'Permainan Slot | Pragmatic Play',
                    title: null,
                    content: null
                },
                pt: {
                    headingOne: 'Permainan Slot | Playtech',
                    title: null,
                    content: null
                },
                fs: {
                    headingOne: 'Permainan Slot | FastSpin',
                    title: null,
                    content: null
                },
                pgs2: {
                    headingOne: 'Permainan Slot | PGsoft',
                    title: null,
                    content: null
                },
                jk: {
                    headingOne: 'Permainan Slot | Joker Gaming',
                    title: null,
                    content: null
                },
                live: {
                    headingOne: 'Permainan Slot | Live22',
                    title: null,
                    content: null
                },
                jili: {
                    headingOne: 'Permainan Slot | Jili',
                    title: null,
                    content: null
                },
                mg: {
                    headingOne: 'Permainan Slot | MicroGaming',
                    title: null,
                    content: null
                },
                ag: {
                    headingOne: 'Permainan Slot | AsiaGaming',
                    title: null,
                    content: null
                },
                haba: {
                    headingOne: 'Permainan Slot | Habanero',
                    title: null,
                    content: null
                },
                next: {
                    headingOne: 'Permainan Slot | Nextspin',
                    title: null,
                    content: null
                },
                jdb: {
                    headingOne: 'Permainan Slot | JDB',
                    title: null,
                    content: null
                },
                net: {
                    headingOne: 'Permainan Slot | Netent',
                    title: null,
                    content: null
                },
                simple: {
                    headingOne: 'Permainan Slot | SimplePlay',
                    title: null,
                    content: null
                },
                xe: {
                    headingOne: 'Permainan Slot | XE88',
                    title: null,
                    content: null
                },
                ttg: {
                    headingOne: 'Permainan Slot | TTG',
                    title: null,
                    content: null
                },
                png: {
                    headingOne: 'Permainan Slot | Play\'n GO',
                    title: null,
                    content: null
                },
            },
            sports: {
                max: {
                    headingOne: 'Sports | SABASPORTS',
                    title: null,
                    content: null
                },
                cmd: {
                    headingOne: 'Sports | CBD368',
                    title: null,
                    content: null
                },
                sbo: {
                    headingOne: 'Sports | SBOBET',
                    title: null,
                    content: null
                }
            },
            fishing: {
                fs: {
                    headingOne: 'Fishing | Fastspin',
                    title: null,
                    content: null
                },
                jdb: {
                    headingOne: 'Fishing | JDB',
                    title: null,
                    content: null
                },
                jili: {
                    headingOne: 'Fishing | Jili',
                    title: null,
                    content: null
                },
                bg: {
                    headingOne: 'Fishing | Big Gaming',
                    title: null,
                    content: null
                },
                sg: {
                    headingOne: 'Fishing | SpadeGaming',
                    title: null,
                    content: null
                },
                live: {
                    headingOne: 'Fishing | Live22',
                    title: null,
                    content: null
                },
                cq9: {
                    headingOne: 'Fishing | CQ9 Gaming',
                    title: null,
                    content: null
                },
                jk: {
                    headingOne: 'Fishing | Joker Gaming',
                    title: null,
                    content: null
                },
            }
        }
    }

    // To use in dynamic footer component
    contentDataDF = {
        "BP": {
            MY: {
                main: {
                    main: {
                        title: "$brand_name Malaysia - No.1 Leading Online Casino in Malaysia 2024",
                        content: [
                            "Welcome to the exceptional gaming world at $brand_name Malaysia, where we guarantee a top-tier gambling experience with substantial returns and remarkable rewards. Our games are meticulously designed to suit players at all proficiency levels, ensuring everyone from novices to experts finds their ideal match.",
                            "Embark on your betting adventure with $brand_name Malaysia. Register now, enjoy our complimentary bonuses, and commence your betting journey today!"
                        ]
                    }
                }
            },
            SG: {
                main: {
                    main: {
                        title: "$brand_name Singapore - The Premier Online Casino Destination in Singapore for 2024",
                        content: [
                            "Step into the extraordinary realm of gaming at $brand_name Singapore, offering a premier gambling experience featuring significant returns and impressive prizes. Our game selection is carefully crafted to cater to all skill levels, providing the perfect game for both beginners and seasoned players.",
                            "Begin your wagering journey with $brand_name Singapore. Sign up today, take advantage of our free bonuses, and start your betting adventure immediately!"
                        ]
                    }
                },
                sports: {
                    wbet: {
                        title: "Wbet",
                        content: [
                            "One of Southeast Asia's most popular sports betting products has been redesigned from the ground up to take on today's online sports betting sector. Online live sports betting provider WBet prides itself on providing the finest possible experience for its clients in running an online bookmaking business with decades of combined industry knowledge from key Asian sports betting market participants."
                        ]
                    },
                    cmd: {
                        title: "Cmd368",
                        content: [
                            "CMD368, an online betting and sports betting platform that was established in Cambodia back in 2016. Since then, CMD368 has gained popularity among Asian players and bettors. CMD368 is especially known in countries such as Malaysia, Vietnam, Indonesia, and Thailand. It became the go-to place for avid sports and e-sports fans."
                        ]
                    },
                },
                casino: {
                    dg: {
                        title: "Dream gaming",
                        content: [
                            "Dream Gaming stands out among online casinos in Malaysia and around Asia. The platform is well-known for using a team of skilled dealers in a real casino environment. Dream Gaming also has high-end equipment and quick technical support, ensuring that players have pleasant high-quality games. The brand lets people participate in games across a variety of platforms. Therefore, players can access the game from anywhere!"
                        ]
                    },
                    bg: {
                        title: "Big gaming casino",
                        content: [
                            "Big Gaming slots were designed specifically to suit Asians by providing quick connections to the gambling industry. Choose the most popular Big Gaming Casino for a diverse selection of games and the best bonuses for Singaporean gamers and earn real money prizes!"
                        ]
                    },
                    sexy: {
                        title: "Sexybaccarat",
                        content: [
                            "This is a call to all Baccarat fans all across the world. If that describes you, keep reading! Despite the fact that there are various online casinos that provide live dealer games, the majority of them are designed to replicate the traditional land-based casino experience. Basically, they're attempting to replicate the appearance and feel of a traditional land-based casino."
                        ]
                    },
                    evo: {
                        title: "Evolution gaming casino",
                        content: [
                            "Is it possible to have a casino gaming company that doesn't make slot machines? This may seem like a difficult endeavor, yet such a corporation does exist. Its name is Evolution Gaming, and it solely provides live casino solutions to the industry, including roulette, blackjack, and other table and card games that normally require human dealers to conduct a session with their online casinos."
                        ]
                    },
                    sa: {
                        title: "Sa gaming casino",
                        content: [
                            "We'll look at the casino game creator SA Gaming and the casinos that use its software. Although the majority of best online casinos are from the United States, Australia, and Europe, Asia has a number of enterprises to be proud of. One of them is SA Gaming Casinos."
                        ]
                    }
                },
                slots: {
                    cq9: {
                        title: "Cq9",
                        content: [
                            "CQ9 Gaming began in online gaming industry in Asia, which explains the Asian flavor of many of its games; it is currently becoming more well-known in the United States and Europe. It claims to be Asia's most popular gaming brand, having more than 150 HTML5 games in its portfolio."
                        ]
                    },
                    kiss: {
                        title: "918kiss",
                        content: [
                            "There's no doubt that 918kiss is one of Malaysia's most popular online slot games. It's so popular that it has gained a huge following in Malaysia and worldwide. So if you're looking for some excitement and want to try your luck at gambling online, 918kiss is a game you should check out."
                        ]
                    },
                    mega: {
                        title: "Mega888",
                        content: [
                            "Online games have become a fixture in the lives of many people who are looking for some form of recreation. However, with the wide variety of current options, one may be left wondering which ones they should get into and which ones would offer the most entertainment. Mega888 online casino is an excellent choice for such individuals since it provides many options for slot machines and card games."
                        ]
                    },
                    pp: {
                        title: "Pragmatic Play",
                        content: [
                            "The online gaming industry is constantly growing and evolving, and as it does so, new ways to enjoy live casino emerges. One such trend is pragmatic Play - a term used to describe playing desktop digital casino games that maximizes potential wins and minimizes losses."
                        ]
                    },
                    live: {
                        title: "Live22",
                        content: [
                            "There are plenty of online casinos in the market. However, only a few casino platforms are specially made for southeast Asian countries. If you wish to play online casino games wherever you are, you should consider downloading Live22 APK."
                        ]
                    },
                    sg: {
                        title: "Spadegaming",
                        content: [
                            "Asia may not be considered a significant player in the online casino game, but Spadegaming is on a mission to put the region on the map. To put things into perspective, the Asian market had always been fond of gambling. However, online casinos are not as popular in Asia as in other parts of the world."
                        ]
                    },
                    next: {
                        title: "Nextspin",
                        content: [
                            "Digitalization has taken over so many industries, and casino games are no exception. Today, plenty of players worldwide have embraced the wonders of the online casino platform. Instead of going to physical casinos, people get to play games on their mobile devices or computers. This means there is the freedom to play casino games wherever and whenever."
                        ]
                    },
                    haba: {
                        title: "Habanero slot",
                        content: [
                            "There are plenty of popular online casinos in the market. Many companies have cemented their position as the leaders in the world when it comes to online slots and casino games. However, not all the best online casinos are famous names. There are hidden gems like Habanero Systems that have enthralled gaming enthusiasts worldwide."
                        ]
                    }
                },
                esports: {
                    cmd: {
                        title: "Esports betting",
                        content: [
                            "In recent years, esports has exploded. While most other sporting events have been put on hold or have undergone dramatic changes, esports has become increasingly popular. This rise in popularity is mainly because players can participate in esports regardless of their location."
                        ]
                    },
                },
            },
            ID: {
                main: {
                    main: {
                        title: "$brand_name - Your Best Choice of Online Casino Platform in Indonesia",
                        content: [
                            "Welcome to $brand_name Indonesia! In $brand_name casino, we promise an unparalleled gambling experience, offering high returns on investment and incredible rewards! Our games are thoughtfully structured across various levels, catering to players of all skill levels. Whether you're a beginner or a seasoned pro, you're sure to find games that perfectly match your style.",
                            "Let's begin your betting journey with $brand_name Indonesia. Sign up, claim the free bonuses and start betting now!"
                        ]
                    }
                }
            },
            TH: {
                main: {
                    main: {
                        title: "$brand_name - Premier Destination of Online Casino in Thailand",
                        content: [
                            "Discover the extraordinary world of gaming at $brand_name Thailand, where we promise an elite gambling experience filled with significant returns and impressive rewards. Our carefully crafted games cater to every level of skill, offering the perfect fit for both beginners and seasoned players alike.",
                            "Begin your betting journey with $brand_name Thailand. Sign up today, take advantage of our free bonuses, and start your gambling adventure right away!"
                        ]
                    }
                }
            }
        },
        "12HUAT": {
            MY: {
                main: {
                    main: {
                        title: "12Huat Casino: Premium Gaming with Exclusive Big Rewards",
                        content: [
                            "12Huat online casino Malaysia is a leading online casino platform that offers a comprehensive gaming experience with a focus on premium entertainment and exclusive rewards. Catering to both new players and seasoned gamblers, 12Huat provides a combination of high-quality games, secure environment, and player-centric approach."
                        ]
                    }
                }
            },
            SG: {
                main: {
                    main: {
                        title: "12Huat Casino: Premium Gaming with Exclusive Big Rewards",
                        content: [
                            "12Huat online casino Singapore is a leading online casino platform that offers a comprehensive gaming experience with a focus on premium entertainment and exclusive rewards. Catering to both new players and seasoned gamblers, 12Huat provides a combination of high-quality games, secure environment, and player-centric approach."
                        ]
                    }
                }
            },
        },
        "BX99": {
            MY: {
                main: {
                    main: {
                        title: "BX99 Casino - The Ultimate Online Casino Malaysia!",
                        content: [
                            "Welcome to BX99 Online Casino Malaysia, the pinnacle of digital gaming where adventure kicks off seamlessly. Voted as Malaysia’s premier online casino, BX99 stands as a beacon of thrill, joy, and limitless possibilities. At the heart of BX99 Malaysia, we’re dedicated to delivering an outstanding gaming experience, merging cutting-edge technology with a diverse game roster and a fortress-like safe gaming environment. Trusted by over 10,000 patrons, it’s clear that BX99 is the ultimate digital haven for play, wins, and sheer enjoyment!"
                        ]
                    }
                }
            },
            SG: {
                main: {
                    main: {
                        title: "BX99 Casino - The Ultimate Online Casino Singapore!",
                        content: [
                            "Welcome to BX99 Online Casino Singapore, the pinnacle of digital gaming where adventure kicks off seamlessly. Voted as Singapore’s premier online casino, BX99 stands as a beacon of thrill, joy, and limitless possibilities. At the heart of BX99 Singapore, we’re dedicated to delivering an outstanding gaming experience, merging cutting-edge technology with a diverse game roster and a fortress-like safe gaming environment. Trusted by over 10,000 patrons, it’s clear that BX99 is the ultimate digital haven for play, wins, and sheer enjoyment!"
                        ]
                    }
                }
            },
        },
        "YE55": {
            MY: {
                main: {
                    main: {
                        title: "YE55 Malaysia Casino: Bet on Your Favorite Casino Games",
                        content: [
                            "YE55 Malaysia Casino, put the thrill of winning right at your fingertips. The casino offers action-packed entertainment with top-tier games that are designed to engage and excite. We believe in creating an experience that goes beyond just playing. Our players enjoy access to some of the best promotions and bonuses in the industry, and VIP treatment like no other. In YE55 Malaysia, we built our platform to ensure smooth and secure transactions, giving you peace of mind while you focus on enjoying the games and winning jackpots."
                        ]
                    }
                }
            },
            SG: {
                main: {
                    main: {
                        title: "YE55 Singapore Casino: Bet on Your Favorite Casino Games",
                        content: [
                            "YE55 Singapore Casino, put the thrill of winning right at your fingertips. The casino offers action-packed entertainment with top-tier games that are designed to engage and excite. We believe in creating an experience that goes beyond just playing. Our players enjoy access to some of the best promotions and bonuses in the industry, and VIP treatment like no other. In YE55 Singapore, we built our platform to ensure smooth and secure transactions, giving you peace of mind while you focus on enjoying the games and winning jackpots."
                        ]
                    }
                }
            }
        }
    }

    constructor() { }

}