<!-- Change country site notice -->
<div class="top-notice" *ngIf="showChangeCountryNotice && !showReminder">
  <div class="d-flex justify-content-center top-notice-content">
    <div style="width:22%"></div>
    <div style="width:56%" class="d-flex align-items-center justify-content-center">
      <span>{{ 'Change to [country] site to see content specific to your location' | translate: {country: ISOCountryCode[countryCodeToChange] | translate} }}</span>
      <button class="btn btn-border mr-2 ml-3" (click)="showChangeCountryNotice = false">{{ 'No, Thanks' | translate }}</button>
      <button class="btn btn-yes mr-2" (click)="onChangeCountrySite()">{{ 'Yes' | translate }}</button>
    </div>
    <div style="width:22%" class="d-flex align-items-center justify-content-end">
    </div>
  </div>
</div>
<!-- BP9 reminder -->
<div class="top-notice" *ngIf="showReminder && !shutdownflag">
  <div class="d-flex justify-content-center top-notice-content">
    <div style="width:22%"></div>
    <div style="width:100%" class="d-flex align-items-center justify-content-center">
      <span [innerHTML]="'Welcome to BP9! Save our new link <b>[link]</b> for your ease of access in the future.' | translate: {link: newLink}"></span>
      <button type="button" class="btn btn-link" (click)="onDoNotShowAgain()">{{ 'Do not show again' | translate }}.</button>
    </div>
    <div style="width:22%" class="d-flex align-items-center justify-content-end">
    </div>
  </div>
</div>
<!-- Main -->
<ng-container *ngIf="hideAppBase; else elseTemplate">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #elseTemplate>
  <app-base *ngIf="!isMobileDetected && !hideAppBase">
    <app-base-inner>
      <router-outlet></router-outlet>
    </app-base-inner>
    <!-- <app-bottombar *ngIf="show && maintenanceDateTime === null"></app-bottombar> -->
    <app-dynamic-footer *ngIf="(mode != 'Members Only' || isUserLoggedIn()) && show && maintenanceDateTime === null"></app-dynamic-footer>
  </app-base>
</ng-template>
<app-popup-announcement *ngIf="announcements$.length > 0" [message]="announcements$" (confirmed)="onUpdateAnnouncement()"></app-popup-announcement>
<app-request-receipt *ngIf="isUserLoggedIn()"></app-request-receipt>
<app-floating-reward *ngIf="isUserLoggedIn()"></app-floating-reward>
<img *ngIf="( (page == 'home' && !pageTitle) || pageTitle == 'Home' ) && countryCode == 'ID' && (siteName == 'BP77'|| siteName == 'BP9') && maintenanceDateTime === null" src="../assets/images/rtp-floating-icon-animated.gif" [class]="checkLivechat() == 'liveChat' ? 'rtp-img-with-livechat' : checkLivechat() == 'sbChat' ? 'rtp-img-with-sbchat' : 'rtp-img'"  (click)="onRTP()">