import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { AesService } from '@core/services/aes.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  constructor(
    private http: HttpClient,
    private aesService: AesService
  ) {}

  async onLoad(): Promise<any> {
    window.OneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      window.OneSignal.push(function () {
        resolve(window.OneSignal);
      });
    });
  }

  onInit() {
    return this.http.get<ApiResponse>(`/onesignal/app`).pipe(
      map(res => {
        const decryptedAppId = this.aesService.decryptWithReqSignKey(res.data.app_id);

        this.onLoad().then((OneSignal) => {
          OneSignal.init({
            appId: decryptedAppId
          });
        });
      })
    );
  }
}
