import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { PushNotificationCTAComponent } from "@shared/push-notification-cta/push-notification-cta.component";
import Echo from "laravel-echo";
import { environment } from '@env/environment';

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  static echo: Echo;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  // all available CTAs
  ctaMap = {
    deposit: '/member/wallet;tab=deposit',
    view: '/member/reward',
    reward: '/member/reward',
    profile: '/member/profile'
  };

  constructor(private snackBar: MatSnackBar) {
    if (WebsocketService.echo == undefined) {
      const SocketIoClient = require("socket.io-client");
      const socketHost: string = environment.socketHost;
      try {
        WebsocketService.echo = new Echo({
          broadcaster: "socket.io",
          client: SocketIoClient,
          host: socketHost,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  getEcho() {
    return WebsocketService.echo;
  }

  getChannel(channel: string) {
    return WebsocketService.echo.channel(channel);
  }

  getCTAPath(cta?: string) {
    if (!cta || typeof cta != 'string') { // if no cta or invalid cta
      return '/';
    }

    return this.ctaMap[cta.toLowerCase()] || '/';
  }

  ctaNotification(data) {
    const snackBarRef = this.snackBar.openFromComponent(PushNotificationCTAComponent, {
      data: data,
      duration: 8000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ["balloon-notification"],
    });

    const cdkOverlayContainer = document.querySelector('.cdk-overlay-container') as HTMLElement;
    cdkOverlayContainer.style.setProperty('z-index', '3147483639', 'important');

    snackBarRef.afterDismissed().subscribe(() => {
      // The snackbar is closed, change the z-index here.
      const cdkOverlayContainer = document.querySelector('.cdk-overlay-container') as HTMLElement;
      cdkOverlayContainer.style.setProperty('z-index', '1000', 'important');
    });
  }
}