import { Router } from '@angular/router';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { Component, OnInit, ViewChild, DoCheck, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { EventEmitterService } from './../../../core/services/event-emitter.service';
import { LiveChatIdService } from '@core/services/live-chat-id.service';
import { LiveChatWidgetModel } from '@livechat/angular-widget';
import { LiveChatWidgetApiModel } from '@livechat/angular-widget';
import { AuthData } from '@core/models/auth-data';
import { MemberBalance } from '@core/models/member-balance.model';
import { isLoggedIn, loggedUser } from '@core/store/auth/auth.selectors';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { Observable, Subject, Subscription, zip, forkJoin, of } from 'rxjs';
import { tap, catchError, map, switchMap, delay } from 'rxjs/operators';
import { AppState } from '@store/reducers';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { LiveChatHttpService } from '@core/services/livechat-http-service';
import { ContactListHttpService } from '@core/services/contact-list-http.service';
import { AuthHttpService } from '@core/services/auth-http.service';
import { LoadingService } from '@core/services/loading-service.service';
import svgIconList from 'assets/icons.json';

declare const $: any;
@Component({
  selector: 'app-sidepanels',
  templateUrl: './sidepanels.component.html',
  styleUrls: ['./sidepanels.component.scss']
})
export class SidePanelsComponent implements OnInit, DoCheck, OnDestroy, AfterViewInit {
  svg: any = svgIconList;

  @ViewChild('liveChatWidget', { static: false }) public liveChatWidget: LiveChatWidgetModel;
  mode: string = window.mode;
  liveChatWidget$: Subscription = new Subscription();
  liveChatApi: LiveChatWidgetApiModel;
  public isLiveChatWidgetLoaded = false;
  language = this.liveChatIdService.getId(localStorage.getItem('country_code'), localStorage.getItem('language_code'));
  visitor = {
    name: JSON.parse(localStorage.getItem('user_data')) === null ? ' ' : JSON.parse(localStorage.getItem('user_data')).username
  }
  resetLiveChat = false;
  currentLanguageCode = localStorage.getItem('language_code')?.toUpperCase();

  isLoggedIn$: Observable<boolean>;
  userProfile: Observable<AuthData>;
  walletBalance: MemberBalance;
  collapse: boolean;
  activeRewards = [];
  contactList$: any;
  contactList = sessionStorage.getItem('contact_us') === null ? [] : JSON.parse(sessionStorage.getItem('contact_us'));
  luckySpinDomain = environment.luckySpinDomain;

  scrHeight: any;
  scrWidth: any;
  gameCategoryList: { rows: [any], total_game_balance: 0 }; // With balance
  onMaintenancePage = false;
  countryCode = localStorage.getItem('country_code');
  isLine = false;
  lineURL: any;
  lineContent: any;

  // Other varible
  providerBalancesIsLoading: number = 0;
  memberBalanceIsLoading: number = 0;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  messages$ = new Subject<any[]>();

  // TEMPORARY USE TO HIDE LUCKY SPIN WHEEL IN PRODUCTION
  currentEnv = environment.domainEnv;

  private subscriptions: Subscription[] = [];
  private subscription = new Subscription();

  constructor(
    private eventEmitterService: EventEmitterService,
    private liveChatIdService: LiveChatIdService,
    private walletHttpService: WalletHttpService,
    public loadingBar: LoadingBarService,
    private transferService: PortalTransferHttpService,
    private dashboardHttpService: DashboardHttpService,
    private store: Store<AppState>,
    private gameCategoryHttpService: GameCategoryHttpService,
    private translateService: TranslateService,
    private router: Router,
    private livechatHttpService: LiveChatHttpService,
    private authHttpService: AuthHttpService,
    private loadingService: LoadingService
  ) {
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.providerBalancesLoadingBehaviourSubject.subscribe(val => {
        this.providerBalancesIsLoading = val;
      })
    );
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.memberBalanceLoadingBehaviourSubject.subscribe(val => {
        this.memberBalanceIsLoading = val;
      })
    );
    // Subscribe to get push update for member balance
    this.subscriptions.push(
      this.walletHttpService.memberBalance.subscribe(res => {
        this.walletBalance = res;
      })
    );
    // Subscribe to get push update for games balance
    this.subscriptions.push(
      this.gameCategoryHttpService.gameProvidersBalance.subscribe(res => {
        this.gameCategoryList = res;
      })
    );
  }

  ngOnInit() {
    setTimeout(() => {
      this.onMaintenancePage = (window.location.pathname) == '/maintenance' ? true : false;
    }, 500)

    this.subscriptions.push(
      this.eventEmitterService.updateUsernameVar = this.eventEmitterService.updateUsernameEmitter.subscribe((username) => {
        this.resetLiveChat = true;
        this.visitor.name = username;
        setTimeout(() => {
          this.resetLiveChat = false;
        }, 200);
      })
    );
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.userProfile = this.store.pipe(select(loggedUser));
    this.collapse = true;

    this.eventEmitterService.contactUsVar = this.eventEmitterService.updateContactUsEmitter.subscribe(() => {
      this.contactList = JSON.parse(sessionStorage.getItem('contact_us'));
      this.currentLanguageCode = localStorage.getItem('language_code')?.toUpperCase();

      // Hard code 'Line' floating icon
      this.lineContent = this.contactList.find(x => x.contact_platform.name === 'Line');
      this.isLine = this.lineContent !== undefined ? true : false;
      this.lineURL = this.lineContent !== undefined && this.lineContent.locales.length > 0 ? this.lineContent.locales[0].link : '';
      this.countryCode = localStorage.getItem('country_code');
    });

    // No need to call these API when in /launch page
    if (window.location.pathname !== '/launch') {
      this.isLoggedIn$.subscribe(res => {
        if (res) {
          setTimeout(async () => {
            this.dashboardHttpService.getActiveRewards().subscribe(res => {
              this.activeRewards = res;
            });
            if (this.walletHttpService.memberBalance.getValue().currency_code === '-') {
              await this.getWalletDetails();
            }
            // this.subscriptions.push(
            //   this.walletHttpService.memberBalance.subscribe(balance => {
            //     this.walletBalance = balance;
            //   })
            // );
          }, 500)
        }
      });
    }
    this.getScreenSize();

  }

  ngAfterViewInit(): void {
    this.liveChatWidget$ = this.liveChatWidget.onChatLoaded.subscribe((api: LiveChatWidgetApiModel) => this.liveChatApi = api);
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        // this.gameCategoryHttpService.getGameCategoryListWithPromo().subscribe();
        setTimeout(async () => {
          const liveChatTranslate = this.livechatHttpService.getTranslation();
          const apiParams = [];
          const cookieData = this.livechatHttpService.getLiveChatInfoCookies();
          if (!cookieData['username']) { apiParams.push('username'); }
          if (!cookieData['membership']) { apiParams.push('membership'); }
          if (!cookieData['balance']) { apiParams.push('balance'); }
          if (!cookieData['last_played_game']) { apiParams.push('last_played_game'); }
          if (!cookieData['kyc_status']) { apiParams.push('kyc_status'); }
          if (!cookieData['next_required_deposit']) { apiParams.push('next_required_deposit'); }
          if (apiParams.length > 0) {
            this.livechatHttpService.getLiveChatInfo(apiParams).subscribe(apiData => {
              const customVariables = { ...cookieData, ...apiData };
              this.liveChatWidget.setCustomVariables(Object.keys(customVariables).map((key) => ({ name: liveChatTranslate[key], value: customVariables[key] })));
            });
          } else {
            const customVariables = cookieData;
            this.liveChatWidget.setCustomVariables(Object.keys(customVariables).map((key) => ({ name: liveChatTranslate[key], value: customVariables[key] })));
          }
        }, 500)
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
    this.liveChatWidget$.unsubscribe();
  }

  ngDoCheck() {
    // this.gameCategoryList = JSON.parse(sessionStorage.getItem('game_providers_balance'));
  }

  toggleSidebar() {
    $(".menu-hamburger").toggleClass("active");
    $(".menu-icon-container").toggleClass("menu-animate");
    if (this.collapse) {
      //opening sidebar
      document.getElementById("mySidebar")
      if (this.scrWidth <= 1336) {
        document.getElementById("mySidebar").style.width = "150px";
        document.getElementById("receipt-floating-bubble").style.left = "160px";
        document.getElementById("rewards-floating-bubble").style.left = "160px";
        if (this.luckySpinDomain !== '') {
          document.getElementById("lucky-spin").style.left = "150px";
        }
      } else if (this.scrWidth <= 1440) {
        document.getElementById("mySidebar").style.width = "150px";
        document.getElementById("receipt-floating-bubble").style.left = "160px";
        document.getElementById("rewards-floating-bubble").style.left = "160px";
        if (this.luckySpinDomain !== '') {
          document.getElementById("lucky-spin").style.left = "150px";
        }
      } else {
        document.getElementById("mySidebar").style.width = "262px";
        document.getElementById("receipt-floating-bubble").style.left = "272px";
        document.getElementById("rewards-floating-bubble").style.left = "272px";
        if (this.luckySpinDomain !== '') {
          document.getElementById("lucky-spin").style.left = "262px";
        }
      }

      this.collapse = false;
    } else {
      //closing sidebar
      this.collapse = true;
      document.getElementById("mySidebar").style.width = "90px";
      document.getElementById("receipt-floating-bubble").style.left = "100px";
      document.getElementById("rewards-floating-bubble").style.left = "100px";
      if (this.luckySpinDomain !== '') {
        document.getElementById("lucky-spin").style.left = "90px";
      }
    }
  }

  onLuckySpin() {
    this.router.navigate(['/luckyspin']);
  }

  onRestore() {
    if (this.providerBalancesIsLoading == 0 && this.memberBalanceIsLoading == 0) {
      this.loadingBar.start();
      const transfers = [];
      const popup = () => {
        this.messages$.next([this.translateService.instant('All credits are in Main Wallet')])
      }

      this.gameCategoryList.rows.map(provider => {
        if (provider.balance > 0.00 && provider.status === 1) {
          transfers.push(this.transferService.transferBy(provider.id, 'all', 2));
        }
      });
      if (transfers.length === 0) {
        this.loadingBar.complete();
        popup();
      }
      zip(...transfers).subscribe(() => {
        this.gameCategoryHttpService.getGameCategoryListWithPromo(true).subscribe();
        this.walletHttpService.getMemberBalance().subscribe();
        this.loadingBar.complete();
      });
    }
  }

  onContact(link: string) {
    if (link !== undefined && link !== null) {
      window.open(link, "_blank");
    }
  }

  onOpenLine() {
    window.open(this.lineURL, "_blank");
  }

  isUserLoggedIn() {
    return this.authHttpService.isUserLoggedIn
  };

  private getWalletDetails() {
    return new Promise<void>((resolve, reject) => {
      this.walletHttpService.getMemberBalance().subscribe(() => resolve());
    });

  }

}
