<footer id="footer" class="dark border-0 footer-custom" *ngIf="!playtechPage" [ngClass]="{'game-launch-page': isLaunchPage}" #footer [style]="(countryCode === 'SG' && currentPageSeoFooter != '' && gameCategoryFooter[currentGameCategory]?.includes(currentPageSeoFooter)) || (countryCode === 'SG' && currentPageSeoFooter === 'main')? '' : 'bottom: 0;'">
  <div class="container p-t-50">
    <ng-container *ngIf="footerList">
      <ng-container *ngFor="let x of counter(lastRow);  let h = index">
        {{updateShowRowSpace(0)}}
        <div class="row footer-style m-t-30 p-b-0">
          <ng-container *ngFor="let row of footerList;  let i = index">
            <ng-container *ngIf="row['web_row'] == h + 1 && showRow == 0 && row['web_column'] > 1">
              <ng-container *ngFor="let r of counter(row['web_column'] - 1);">
                <div class="p-l-5 p-r-5" class="col-2"></div>
              </ng-container>
            </ng-container>
            <div class="p-l-5 p-r-5" [ngClass]="'col-'+row.number_column * 2" *ngIf="row['web_row'] == h + 1">
              {{updateShowRowSpace(1)}}
              <p class="footer-title" *ngIf="row.display_title && row.section_titles_name">{{ row.section_titles_name | uppercase }}</p>
              <ng-container *ngIf="row.details">
                <ng-container *ngIf="row.details.imageText">
                  <div class="row h-100 imgtxt-container d-flex" [style.align-content]="row.details.imageText.image_vertical_align == 1? 'start' : (row.details.imageText.image_vertical_align == 2 ? 'center': 'end')">
                    <div class="col-12 d-flex" [style.justify-content]="row.details.imageText.image_horizontal_align == 1? 'start' : (row.details.imageText.image_horizontal_align == 2 ? 'center': 'end')">
                      <ng-container *ngIf="row.redirect_link != null">
                        <a [routerLink]="row.redirect_link" class="m-0 d-contents">
                          <img [src]="row.details.imageText.image_path" class="w-100 h-auto" [style.min-width]="row.details.imageText.image_min_width + 'px'" [style.max-width]="row.details.imageText.image_max_width + 'px'" />
                        </a>
                      </ng-container>
                      <ng-container *ngIf="row.redirect_link == null">
                        <img [src]="row.details.imageText.image_path" class="w-100 h-auto" [style.min-width]="row.details.imageText.image_min_width + 'px'" [style.max-width]="row.details.imageText.image_max_width + 'px'" />
                      </ng-container>
                    </div>
                    <div class="col-12" *ngIf="row.details.imageText.content">
                      <p [innerHTML]="row.details.imageText.content"></p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="row.details.IconQuickLink">
                  <ng-container *ngFor="let row2 of row.details.IconQuickLink">
                    <ng-container *ngIf="row2.link && !row2.icon_path">
                      <ng-container *ngIf="row2.link != null">
                        <a (click)="onRedirect(row2.link)">{{ row2.name }}</a>
                      </ng-container>
                      <ng-container *ngIf="row2.link == null ">
                        {{ row2.name }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="row.details.IconQuickLink">
                  <div class="bottom-payment-method2" *ngIf="hasIcons(row.details.IconQuickLink)">
                    <ng-container *ngFor="let row2 of row.details.IconQuickLink">
                      <ng-container *ngIf="row2.icon_path">
                        <ng-container *ngIf="row2.link">
                          <a class='m-b-10' href="{{ row2.link }}" target="_blank"><img [ngClass]="row.footer_section_name === 'Follow Us' ? '' : 'img-non-follow-us'" [src]="row2.icon_path" class="img-fluid"></a>
                        </ng-container>
                        <ng-container *ngIf="row2.link_with_internal_link != null && !row2.link">
                          <a class='m-b-10' [routerLink]="row2.link_with_internal_link"><img [ngClass]="row.footer_section_name === 'Follow Us' ? '' : 'img-non-follow-us'" [src]="row2.icon_path" class="img-fluid"></a>
                        </ng-container>
                        <ng-container *ngIf="row2.link_with_internal_link == null && !row2.link">
                          <a class='m-b-10'>
                            <img [ngClass]="row.footer_section_name === 'Follow Us' ? '' : 'img-non-follow-us'" [src]="row2.icon_path" class="img-fluid">
                          </a>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>

      </ng-container>
    </ng-container>
    <div class="row footer-style m-b-0">
      <p class="copyright-text">{{ 'Copyright' | translate }} {{ '© [currentYear] $brand_name. All rights reserved.' | translate : { 'currentYear' : getCurrentYear() } }}</p>
    </div>

    <div class="seo-container row footer-style" id="seo-container" [style]="(currentPageSeoFooter != '' && gameCategoryFooter[currentGameCategory]?.includes(currentPageSeoFooter)) || currentPageSeoFooter === 'main' ? '' : 'display: none;'" *ngIf="!(sitePrefix == 'YE55' && countryCode == 'SG' && languageCode == 'ZH')"><!-- If condition is to handle YE55 SG_ZH no content -->
      <div style="overflow: hidden;" [@seoSlider]="isSeoExpanded ? 'in' : 'out'">
        <p *ngIf="isSeoExpanded" class="mb-3">
          <a class="hide-info" (click)="onSlideSeo()">{{ 'Hide Info' | translate }}</a>
        </p>

        <ng-container *ngIf="seoFooterData[sitePrefix] && seoFooterData[sitePrefix][countryCode]">
          <!-- Main Start -->
          <ng-container *ngIf="seoFooterData[sitePrefix][countryCode]['main'] && seoFooterData[sitePrefix][countryCode]['main']['main']">
            <p class="mb-3" [hidden]="isSeoExpanded || currentPageSeoFooter !== 'main'">
              <span class="seo-title">{{ seoFooterData[sitePrefix][countryCode].main.main.title | translate }}</span><br>
              <span class="seo-description" *ngFor="let item of seoFooterData[sitePrefix][countryCode].main.main.content; let i = index" [id]="'s1-' + (i+1)">{{ item | translate }}</span>
              &nbsp;
              <span class="read-more" (click)="onSlideSeo()">{{ 'Read More' | translate}}</span>
            </p>
            <app-main-footer *ngIf="currentGameCategory === 'main'" [hidden]="!isSeoExpanded || currentPageSeoFooter !== 'main'"></app-main-footer>
          </ng-container>
          <!-- Main End  -->

          <!-- Game Start-->
          <ng-container *ngIf="seoFooterData[sitePrefix][countryCode][currentGameCategory] && seoFooterData[sitePrefix][countryCode][currentGameCategory][currentPageSeoFooter]">
            <p class="mb-3" [hidden]="isSeoExpanded || currentPageSeoFooter == 'main'">
              <span class="seo-title">{{ seoFooterData[sitePrefix][countryCode][currentGameCategory][currentPageSeoFooter].title | translate }}</span><br>
              <span class="seo-description" *ngFor="let item of seoFooterData[sitePrefix][countryCode][currentGameCategory][currentPageSeoFooter].content; let i = index" [id]="'s1-' + (i+1)">{{ item | translate }}</span>
              &nbsp;
              <span class="read-more" (click)="onSlideSeo()">{{ 'Read More' | translate}}</span>
            </p>
            <app-sports-footer [provider]="currentPageSeoFooter" *ngIf="currentGameCategory === 'sports'" [hidden]="!isSeoExpanded || currentGameCategory !== 'sports'"></app-sports-footer>
            <app-casino-footer [countryCode]="countryCode" [provider]="currentPageSeoFooter" *ngIf="currentGameCategory === 'casino'" [hidden]="!isSeoExpanded || currentGameCategory !== 'casino'"></app-casino-footer>
            <app-slots-footer [provider]="currentPageSeoFooter" *ngIf="currentGameCategory === 'slots'" [hidden]="!isSeoExpanded || currentGameCategory !== 'slots'"></app-slots-footer>
            <app-esports-footer [provider]="currentPageSeoFooter" *ngIf="currentGameCategory === 'esports'" [hidden]="!isSeoExpanded || currentGameCategory !== 'esports'"></app-esports-footer>
          </ng-container>
          <!-- Game End-->
        </ng-container>
      </div>
    </div>
  </div>
</footer>