<div class="block mx-auto ma-box modal-box">
    <div class="modal1" id="modal-login">
        <div class="d-flex justify-content-between">
            <button title="Close (Esc)" type="button" class="mfp-close m-t-10 m-r-10" (click)="onCloseDialog()">×</button>    
        </div>
        <mat-horizontal-stepper linear #stepper>
            <mat-step>
                <div class="rejection-notice">
                    <span [innerHTML]="svg.dangerIcon | safeHtml" class="msg-icon"></span>
                    <h4 class="m-b-10">{{ 'Verification Failed' | translate }}</h4>
                    <p>{{ 'We failed to verify your Ewallet. Kindly reupload the document request or contact Support team for assistance.' | translate }}</p>
                    <div class="ewallet-detail row">
                     <!-- Ewallet-->
                     <label class="col-5 m-0 py-1">{{ 'E-Wallet' | translate }}</label>
                     <label class="col-1 m-0 py-1 px-0">:</label>
                     <label class="col-6 m-0 py-1 px-0">{{ this.data.bank_name }}</label>
                     <!-- Account Number -->
                     <label class="col-5 m-0 py-1">{{ 'Account Number' | translate }}</label>
                     <label class="col-1 m-0 py-1 px-0">:</label>
                     <label class="col-6 m-0 py-1 px-0">{{ this.data.account_number }}</label>
                    </div>
                    <div class="d-flex justify-content-around">
                        <button type="button" class="button-blue w-50 mr-1 bottom-action-btn" (click)="onCloseDialog()">{{ 'Close' | translate }}</button>
                        <button type="button" class="button-orange w-50 ml-1 bottom-action-btn" matStepperNext>{{ 'Reupload' | translate }}</button>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <form class="row" [formGroup]="form" id="ewallet-verification-modal-form" name="ewallet-verification-modal-form">
                    <div class="col-12">
                        <div class="p-t-20 p-l-20 p-r-20">
                            <div class="fancy-title">
                                <span [innerHTML]="svg.backIcon | safeHtml" class="title-action-btn" *ngIf="stepper.selectedIndex > 0" (click)="stepper.previous()"></span>
                                <span [innerHTML]="svg.receiptIcon | safeHtml" class="title-image"></span>
                                <h3>{{ 'Reupload Document' | translate }}</h3>
                            </div>
                            <hr>
                            <div class="col-12 p-l-0 p-r-0">
                                <div class="ewallet-detail mb-4">
                                    <p class="text-center m-b-0">
                                        {{ 'Reason' | translate }}: {{ this.data.reason }}
                                    </p>
                                 </div>
                            </div>
                            <div class="row">
                                <div class="col d-flex flex-row flex-wrap">
                                   <div class="receipt-box d-flex justify-content-center align-items-center" *ngFor="let image of uploaded_images; let i = index">
                                      <img *ngIf="!image.isPdf" [src]="image.path" alt="{{ 'Image Preview' | translate }}" />
                                      <iframe *ngIf="image.isPdf" width="100%" height="100%" [src]="image.path" frameborder="0" style="border:0" allowfullscreen></iframe>
                                      <div class="trash-img-div d-flex justify-content-end align-items-start">
                                         <span [innerHTML]="svg.trashIcon | safeHtml" (click)="onRemoveFileMulti(i)"></span>
                                      </div>
                                   </div>
                                   <div *ngIf="uploaded_images.length < max_images" class="receipt-box d-flex justify-content-center align-items-center">
                                      <input type="file" accept="image/*, application/pdf" (change)="onUploadFileMulti($event)" #myFileInput hidden />
                                      <span *ngIf="!uploadInProgress && !uploadedImage" class="upload-action-btn" [innerHTML]="svg.uploadIcon | safeHtml" (click)="myFileInput.click()"></span>
                                      <span *ngIf="uploadInProgress" class="spinner-border"></span>
                                   </div>
                                </div>
                            </div>
                            <div class="col-12 p-l-0 p-r-0">
                               <p class="p-l-0 p-r-0">{{ 'Max file size is 10MB'| translate}}</p>
                            </div>
                            <div class="col-12 p-l-0 p-r-0 text-center">
                               <button type="submit" class="button-blue w-100" [ngClass]="{'disabled': disableButton}" [disabled]="disableButton" (click)="onSubmit()">{{ 'Submit' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
 </div>
 <app-swal-alert [message]="messages$ | async" [success]="isSuccess" [ewalletVerification]="true"></app-swal-alert>